export default {
  prefix: 'sckp-',
  storage: sessionStorage,
  isInvalid: (val) => [null, undefined, NaN].includes(val),
  get(key = '') {
    if (typeof key !== 'string') {
      throw new Error('cache set key type not string');
    }
    key = this.prefix + key;
    let val = this.storage.getItem(key);

    try {
      val = JSON.parse(val);
    } catch (e) {
      //
    }
    return val;
  },
  set(key = '', val = null) {
    if (val === null && typeof key === 'object') {
      val = key;
      key = '';
    }
    key = this.prefix + key;

    if (this.isInvalid(val)) {
      this.storage.removeItem(key);
    } else {
      this.storage.setItem(key, JSON.stringify(val));
    }
  },
};
