import loadjs from '@/features/common/libs/load';

loadjs(
  'https://cdn.jsdelivr.net/npm/localstorage-slim@2.3.0/dist/localstorage-slim.js',
  'localstorageSlim'
);

/**
 * https://github.com/digitalfortress-tech/localstorage-slim
 * ls.set('key2', value, { ttl: 5 });
 * ls.get('key2');  // within 5 secs => { a: "currentdate", b: "null", c: false, d: 'superman', e: 1234 }
 * @param key
 * @param val
 * @param options
 * @returns {Promise<undefined>}
 */
export default async (key, val = null, options = null) => {
  await loadjs.ready('localstorageSlim');
  const { ls } = window;

  if (!ls) throw new Error('libs not localstorage-slim');

  if (val) {
    ls.set(key, val, options);
    return ls.get(key);
  }

  if (key) {
    return ls.get(key);
  }

  return ls;
};
