let lastHash = location.hash;
export default () => {
  if (history.length && lastHash !== location.hash) {
    lastHash = location.hash;
    history.back();
    return;
  }
  const { WeixinJSBridge } = window;
  if (!WeixinJSBridge) return;
  //安卓关闭页面
  document.addEventListener(
    'WeixinJSBridgeReady',
    () => {
      WeixinJSBridge.call('closeWindow');
    },
    false
  );
  //ios关闭页面
  WeixinJSBridge.call('closeWindow');
};
