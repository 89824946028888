import { defineStore } from 'pinia';
import axios from '@/features/api/axios';
import throttlePromise from '@/features/common/utils/throttlePromise';
import { webServiceIp } from '@/features/common/services/qqMap';
import checkAuth from '@/features/auth/check';

let latestUpdateTimestamp = null;
export default defineStore('me', {
  state: () => ({
    me: null,
    shareHiddenInfo: false,
  }),
  getters: {
    hasWxUserInfo: (state) =>
      state.me?.head_portrait &&
      state.me?.nickname &&
      !state.me.nickname.startsWith('wx_'),
    isDst: (state) => state.me?.user_type === 2,
    isAgent: (state) => state.me?.is_regional === 1,
    cityId: (state) => state.me?.s_city || state.me?.city_id || '',
    city: (state) => state.me?.s_city_name || state.me?.city || '',
    shareId: (state) => state.me?.show_id || '',
  },
  actions: {
    setCity(id, name) {
      this.me.city_id = +id;
      this.me.city = name;
      this.me.s_city = +id;
      this.me.s_city_name = name;
    },
    async getOrSetShareHiddenInfo(val = null) {
      const data = {};
      if (val !== null) {
        data.type = val ? -1 : 1;
        this.shareHiddenInfo = val;
      }
      const { show = null } = await axios.post(
        '/v2/student/handle-posters-setting',
        data
      );

      if (show !== null) {
        this.shareHiddenInfo = show <= -1;
      }
    },
    async fetch() {
      if (latestUpdateTimestamp && latestUpdateTimestamp > Date.now() - 500) {
        return;
      }
      latestUpdateTimestamp = Date.now();

      const { info } = await throttlePromise(axios.get)(
        '/v2/student/member-info'
      );

      if (!info || typeof info !== 'object') return checkAuth(true);

      // info.city_id = '';
      // info.city = '';
      this.me = formatMe(info);

      if (!info.s_city || !info.city_id) {
        webServiceIp().then((res) => {
          const { ad_info } = res;
          if (!ad_info) return;
          this.me.city_id = String(ad_info.adcode).replace(/\d{2}$/, '00');
          this.me.city = ad_info.city;
        });
      }

      if (this.isDst) {
        this.getOrSetShareHiddenInfo();
      }
    },
  },
});

const formatMe = (me) => {
  const toIntKeys =
    'user_type,user_status,is_regional,is_new,id,audit_course,cash_push,commission_push,the_front,qr_level'.split(
      ','
    );
  for (const i of toIntKeys) {
    if (!{}.propertyIsEnumerable.call(me, i)) continue;
    me[i] = +me[i];
  }
  return me;
};
