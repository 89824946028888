import { ref } from 'vue';
const show = ref(0);
export const plpStart = () => {
  show.value = 1;
};
export const plpEnd = () => {
  show.value = 0;
};
export default {
  name: 'pageLoadProgress',
  computed: {
    show() {
      return !!show.value;
    }
  }
};