import pageLoadProgress from '@/features/common/components/pageLoadProgress.vue';
import Tabbar from '@/components/Tabbar';
import weixinAuthorizeUserInfoMask from '@/components/weixinAuthorizeUserInfoMask';
export default {
  name: 'App',
  components: {
    Tabbar,
    pageLoadProgress,
    weixinAuthorizeUserInfoMask
  },
  data() {
    return {
      isRouterAlive: true
    };
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    }
  }
};