export default (keys, useHistory = false) => {
  if (typeof keys === 'string') keys = [keys];
  let replaceUrl = location.href
    .replace(new RegExp(`${location.origin}//?`), '/')
    .replace(new RegExp(`&?(${keys.join('|')})=([\\w-]+)`, `g`), '')
    .replace(/\?&/, '?')
    .replace(/\?#/, '#')
    .replace(/\?$/, '');
  // tips: 必须得在路由初始化前后执行

  if (useHistory) {
    history.replaceState(null, null, replaceUrl);
  } else {
    location.replace(replaceUrl);
  }
};
