var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('van-popup', {
    staticClass: "bg-black/60 shadow-2xl p-2",
    attrs: {
      "value": _vm.show,
      "show": _vm.show,
      "round": "",
      "close-on-click-overlay": false,
      "overlay-class": "bg-transparent"
    }
  }, [_c('van-loading', {
    attrs: {
      "color": "#fff",
      "vertical": ""
    }
  }, [_vm._v("页面加载中…")])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };