import axios from 'axios';
import { Dialog, Toast } from 'vant';
import checkAuth from '../auth/check';
import { tokenStorage } from '../auth/token';
import { api as apiOrigin } from '@/features/auth/originGet';
import closeWindow from '@/features/weixin/utils/closeWindow';
import { axiosResponseHandle as handlePostersSetting } from '@/features/coach/components/HandlePostersSetting';

const instance = axios.create();

export const requestHandle = (config) => {
  const token = tokenStorage();

  // 接口容错处理
  if (!config.url.match(/^\/?(wx|v2)/)) {
    config.url = ('/v2/' + config.url).replace('//', '/');
  }

  if (token && !config.url.match(/^\/?wx/)) {
    if (!config.params) config.params = {};
    Object.assign(config.params, {
      'access-token': token,
    });
  }
  config.baseURL = apiOrigin();
};

instance.interceptors.request.use((config) => {
  requestHandle(config);

  return config;
});

instance.interceptors.response.use(
  (response) => {
    let { code, message, data } = response.data;
    code = +code;
    if (code === 200) {
      // if (typeof data.status !== 'undefined') {
      //   if (data.status === 1) return data;
      //   return Promise.reject(data);
      // }
      handlePostersSetting(response);

      if (
        /test|jkt2|192/.test(location.hostname) &&
        response.config.url.includes('/lesson/vod') &&
        +data?.info?.play_default === 1 &&
        !data?.info?.source
      ) {
        data.info.source = 'https://dora-doc.qiniu.com/004.m3u8';
      }

      return data;
    }
    if (code === 401) {
      checkAuth(true);
      return Promise.reject();
    }
    if (code === 403) {
      Dialog.alert({
        message: data.message,
      }).then(() => {
        closeWindow();
      });
      return Promise.reject();
    }
    if (code === 429) {
      window.location.reload();
      return Promise.reject();
    }
    if (code === 500) {
      Toast('error 500: ' + (data?.errorMessage || message));
      return Promise.reject(data?.errorMessage || message);
    }
    if (code === 402) {
      if (!location.href.match(/update/)) {
        location.replace('/#/update=' + data.message);
      }
      return Promise.reject(data);
    }
    return Promise.reject(code + ':' + message);
  },
  (error) => {
    if (error.message.includes('Network')) {
      return Promise.reject('');
      // Dialog.alert({
      //   message: '网络错误或请求超时',
      //   confirmButtonText: '刷新',
      // }).then(() => {
      //   window.location.reload();
      // });
    }
    return Promise.reject(error);
  }
);

export default instance;

export const fetch = (options) => instance({ api: true, ...options });
