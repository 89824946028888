import { serviceEnv } from '@/config/env';

export const newFrontEnd = {
  release: 'https://stu.jiakao520.cn',
  test: 'https://m.test.jiakao520.cn',
}[serviceEnv];

/**
 * @type {api:String,share:String} coa
 * @type {{api:String:share:String}} stu
 */
export const { stu, coa } = {
  release: {
    stu: {
      api: 'https://m.jiakao520.cn',
      share: 'https://stu.jiakao520.com.cn',
    },
    coa: {
      api: 'https://w.jiakao520.com',
      share: 'https://dst.jiakao520.com.cn',
    },
  },
  test: {
    stu: {
      api: 'https://web.jkt2.jiakao520.cn',
      share: 'https://web.jkt2.jiakao520.com.cn',
    },
    coa: {
      api: 'https://web.jkt2.jiakao520.com',
      share: 'https://dst.jkt2.jiakao520.com.cn',
    },
  },
}[serviceEnv];
