import "core-js/modules/es.array.push.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.items.find(i => i.path === _vm.$route.path) ? _c('van-tabbar', {
    staticClass: "sticky bottom-0 left-0",
    attrs: {
      "fixed": false,
      "active-color": "#22c359"
    },
    model: {
      value: _vm.active,
      callback: function ($$v) {
        _vm.active = $$v;
      },
      expression: "active"
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c('van-tabbar-item', {
      key: index,
      attrs: {
        "name": item.path
      },
      on: {
        "click": function ($event) {
          return _vm.$router.push(item.path);
        }
      },
      scopedSlots: _vm._u([{
        key: "icon",
        fn: function (props) {
          return [_c('img', {
            attrs: {
              "src": props.active ? item.active : item.normal
            }
          })];
        }
      }], null, true)
    }, [_c('span', [_vm._v(_vm._s(item.title))])]);
  }), 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };