export default {
  components: {},
  data() {
    return {
      active: '',
      items: [{
        title: '首页',
        path: '/',
        normal: require('../assets/images/footer/home.png'),
        active: require('../assets/images/footer/home_active.png')
      }, {
        title: '我的课程',
        path: '/Curriculum',
        normal: require('../assets/images/footer/curriculum.png'),
        active: require('../assets/images/footer/curriculum_active.png')
      }, {
        title: '个人中心',
        path: '/My',
        normal: require('../assets/images/footer/my.png'),
        active: require('../assets/images/footer/my_active.png')
      }]
    };
  },
  watch: {
    '$route.path': {
      handler(val) {
        if (val !== this.active) {
          this.active = val;
        }
      },
      immediate: true
    }
  }
};