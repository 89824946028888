import format from 'date-fns/format';
import locale from 'date-fns/locale/zh-CN';
// https://date-fns.org/v2.29.2/docs/format
Object.defineProperty(Date.prototype, 'format', {
  enumerable: false,
  configurable: true,
  writable: true,
  value(dirtyFormatStr = null, dirtyOptions = null) {
    if (!this) {
      throw new TypeError('Date.prototype.format called on null or undefined');
    }
    if (!dirtyFormatStr) dirtyFormatStr = 'yyyy-MM-dd HH:mm:ss';
    if (!dirtyOptions) dirtyOptions = {};
    if (!dirtyOptions.locale) dirtyOptions.locale = locale;
    return format(this, dirtyFormatStr, dirtyOptions);
  },
});
Object.defineProperty(Number.prototype, 'timeFormat', {
  enumerable: false,
  configurable: true,
  writable: true,
  value(dirtyFormatStr = null, dirtyOptions = null) {
    return String(this).timeFormat(dirtyFormatStr, dirtyOptions);
  },
});
Object.defineProperty(String.prototype, 'timeFormat', {
  enumerable: false,
  configurable: true,
  writable: true,
  value(dirtyFormatStr = null, dirtyOptions = null) {
    let time = Date.parse(this);
    if (!time || time < Date.parse('2000')) time = 0;
    if (!/\d{10}|\d{13}/.test(this) && !time) {
      throw new TypeError(`Invalid timestamp`);
    }
    return new Date(time || +this.padEnd(13, '0')).format(
      dirtyFormatStr,
      dirtyOptions
    );
  },
});
