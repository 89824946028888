const handleFontSize = () => {
  if (typeof window.WeixinJSBridge === 'undefined') return;
  const { WeixinJSBridge } = window;
  // 设置网页字体为默认大小
  WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
  // 重写设置网页字体大小的事件
  WeixinJSBridge.on('menu:setfont', function () {
    WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
  });
};

export default () => {
  if (typeof window.WeixinJSBridge !== 'undefined') {
    handleFontSize();
  } else if (document.addEventListener) {
    document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
  } else if (document.attachEvent) {
    document.attachEvent('WeixinJSBridgeReady', handleFontSize);
    document.attachEvent('onWeixinJSBridgeReady', handleFontSize);
  }
};
