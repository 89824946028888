export const wxParams = {
  signatureRequestPromise: null,
  jsApiList: [
    'updateAppMessageShareData',
    'updateTimelineShareData',
    // 'hideMenuItems',
    // 'showMenuItems',
    'chooseWXPay',
    'scanQRCode',
  ],
  openTagList: ['wx-open-launch-weapp'],
};

export const authorize = {
  uri: 'https://open.weixin.qq.com/connect/oauth2/authorize?',
  appid: '',
  state: Date.now(),
};
