import stringify from 'qs/lib/stringify';
import sortObj from '../common/utils/sortObj';
import { authorize } from './config';
import { isWeixin } from '@/features/weixin/utils';
import cache from '@/features/common/utils/storageCommonKeyPrefix';
import locationHrefReplaceSearchKey from '@/features/common/utils/locationHrefReplaceSearchKey';

cache.prefix = 'wxAuthorize';

export const codeStateRegExp = /[?&]code=(.*)&state=(\w+)/;

export const defaultConfig = {
  scope: 'snsapi_base',
};
export const builderAuthorizeUrl = (config) => {
  let search = {
    appid: authorize.appid,
    state: authorize.state,
    response_type: 'code',
    redirect_uri: location.href.replace(codeStateRegExp, ''),
    ...defaultConfig,
    ...(config || {}),
  };

  if (!['snsapi_base', 'snsapi_userinfo'].includes(search.scope)) {
    search.scope = 'snsapi_base';
  }
  search = sortObj(search);
  if (search.scope === 'snsapi_userinfo') {
    search.forcePopup = true;
    // search.forceSnapShot = true;
  }

  return `${authorize.uri}${stringify(search)}#wechat_redirect`;
};

export const hasCodeAndState =
  codeStateRegExp.test(location.href) || cache.get();

const snsapiBase = async (config) => {
  //2 获取url 的 code & state 存缓存，并移除url的 code & state
  const [, code, state] = location.href.match(codeStateRegExp) || [];
  if (code && state) {
    cache.set({ code, state });

    if (!/android/i.test(navigator.userAgent)) {
      locationHrefReplaceSearchKey(['code', 'state']);
      if (codeStateRegExp.test(location.search)) throw '';
    } else {
      history.go(-1);
      throw '';
    }
  }
  //3 获取缓存的 code & state 返回
  if (cache.get()) {
    const { code, state } = cache.get();
    if (!code || !state) return history.back();
    cache.set();
    cache.set('redirectCount');
    return { code, state };
  }
  //1 重定向到微信
  if ((isWeixin || !location.port) && cache.get('redirectCount') < 3) {
    cache.set('redirectCount', cache.get('redirectCount') + 1);
    location.replace(builderAuthorizeUrl(config));
  }
  throw '';
};

export default snsapiBase;

export const snsapiUserInfo = (config = {}) =>
  snsapiBase({ scope: 'snsapi_userinfo', ...config });
