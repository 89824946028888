import { authorize, wxParams } from '@/features/weixin/config';
import { isTestHostApiRelease, serviceEnv } from '@/config/env';
import { stu } from '@/config/origin';
import axios from '@/features/api/axios';
import { isWeixin } from '@/features/weixin/utils';

if ((location.port || isTestHostApiRelease) && isWeixin) {
  authorize.uri = `${stu.api}/wxauthorize.php?`;
}

authorize.state = 0;

authorize.appid = {
  release: 'wx554c2304a963786c',
  test: 'wxf52383dd3dde8e29',
}[serviceEnv];

wxParams.signatureRequestPromise = (jsApiList, url) =>
  axios.get('/wx/sdk', {
    params: {
      jsapi: jsApiList,
      url,
    },
  });
