import { config } from '@/features/common/utils/setTitle';

export const isTestHost = /jkt2|test|localhost|127|192/.test(location.host);

export const serviceEnv = isTestHost ? 'test' : 'release';
// export const serviceEnv = 'release';

export const isTestHostApiRelease = isTestHost && serviceEnv === 'release';

export const siteTitle = {
  release: '掌上考场',
  // test: '实景考场-测试',
  test: '实景考场',
}[serviceEnv];

config.defaultTitle = siteTitle;
