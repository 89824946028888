import loadjs from 'loadjs';

/*
jsdelivr.stevie.top
jsd.compc.cc
cdn.jsdelivr.net
fastly.jsdelivr.net
gcore.jsdelivr.net
*/

const load = (paths, arg1, arg2) => {
  if (!Array.isArray(paths)) {
    paths = [paths];
  }
  paths = paths.map((i) => {
    if (
      !location.port &&
      /http.*js$/.test(i) &&
      !/qq\.com|alicdn|qcloud/.test(i)
    ) {
      // i = '/static.php?url=' + encodeURIComponent(i);
      i = 'https://m.jiakao520.cn/static.php?' + i;
    }

    return i;
  });

  const defaultConfig = {
    // async: false,
    returnPromise: true,
  };

  if (arg1 && arg1.trim) {
    if (loadjs.isDefined(arg1)) {
      return load.ready(arg1);
    }
    if (!arg2) arg2 = {};
    Object.assign(arg2, defaultConfig);
  } else {
    if (!arg1) arg1 = {};
    Object.assign(arg1, defaultConfig);
  }

  return loadjs(paths, arg1, arg2);
};

load.ready = (key) =>
  new Promise((resolve, reject) =>
    loadjs.ready(key, { success: resolve, error: reject })
  );

export default load;
