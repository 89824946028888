import "core-js/modules/es.regexp.exec.js";
import useMeStore from '@/features/auth/useMeStore';
const meStore = useMeStore();
export default {
  name: 'HandlePostersSetting',
  computed: {
    meStore() {
      return meStore;
    }
  }
};
export const axiosResponseHandle = response => {
  if (!meStore.isDst || !meStore.shareHiddenInfo) return;
  if (/lesson\/line-figure/.test(response.config.url)) {
    const allowPath = /linecard|downLine|lessonInfo/.test(location.hash);
    const {
      data: res
    } = response.data;
    if (allowPath && res) {
      //res.line_name = '';
      res.mobile_phone = '';
    }
  }
  if (/student\/line/.test(response.config.url)) {
    const allowPath = /advertcard|post_card|linecard|share/.test(location.hash);
    const {
      posters: res
    } = response.data.data;
    if (allowPath && res) {
      //res.line_name = '';
      res.mobile_phone = '';
    }
  }
  if (/student\/member-info/.test(response.config.url)) {
    const allowPath = /share|invite|prove/.test(location.hash);
    const {
      info: res
    } = response.data.data;
    if (allowPath && res) {
      //res.nickname = '';
      //res.line_name = '';
      res.mobile_phone = '';
    }
  }
};