import axios from '@/features/api/axios';
import uniq from 'lodash/uniq';
import ls from '@/features/common/libs/localstorageSlim';

import {
  webServiceIp,
  geolocationGetLocation,
} from '@/features/common/services/qqMap';
import useMeStore from '@/features/auth/useMeStore';

export default async () => {
  const meStore = useMeStore();

  let res = await webServiceIpAdapter();

  if (
    !sessionStorage.getItem('webServiceIpAdapter') ||
    sessionStorage.getItem('webServiceIpAdapter') !== res.address
  ) {
    sessionStorage.setItem('webServiceIpAdapter', res.address);
    res.address += appendUA();

    request({
      ...res,
    });
  }

  if (
    meStore.isDst &&
    !(await ls('geolocationGetLocationAdapter')) &&
    (/\/($|\?|c_details|home|lessonInfo)/.test(location.hash) || !location.hash)
  ) {
    ls('geolocationGetLocationAdapter', 1, { ttl: 60 * 60 * 24 });
    geolocationGetLocationAdapter().then((geoRes) => {
      if (!geoRes.address || res.address === geoRes.address) return;
      request({
        ...res,
        ...geoRes,
      });
    });
  }
};

const request = (data) =>
  axios.post('/v2/student/login-log', { url: location.href, ...data });

const cacheKey = 'loginLogSystemCacheMonth';
const appendUA = () => {
  if (+localStorage.getItem(cacheKey) === new Date().getMonth()) {
    return '';
  }
  localStorage.setItem(cacheKey, new Date().getMonth());
  let [system] =
    navigator.userAgent.match(/OS \d+(_\d+)?|Android \d+(\.\d+)?/i) || [];
  if (system && typeof system === 'string') {
    system = system.replace(/os (\d+)_(\d+)/i, 'IOS $1.$2');
    system = ` ${system}`;
  }
  return system || '';
};

const geolocationGetLocationAdapter = async () => {
  try {
    const res = await geolocationGetLocation({ timeout: 9999 });
    return {
      address: uniq([res.province, res.city, res.addr]).join(''),
      longitude: res.lng,
      latitude: res.lat,
    };
  } catch (e) {
    //
  }
  return {};
};

const webServiceIpAdapter = async () => {
  const res = await webServiceIp();
  return {
    address: uniq([
      res.ad_info.province,
      res.ad_info.city,
      res.ad_info.district,
    ]).join(''),
    adcode: res.ad_info.adcode,
    longitude: res.location.lng,
    latitude: res.location.lat,
    ip: res.ip,
  };
};
