var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.show ? _c('div', {
    staticClass: "fixed inset-0 translate-z-0 bg-white/10 z-50",
    on: {
      "click": _vm.maskClick
    }
  }) : _vm._e(), _vm.show ? _c('van-popup', {
    staticClass: "rounded-b-lg",
    attrs: {
      "position": "top"
    },
    model: {
      value: _vm.popup.show,
      callback: function ($$v) {
        _vm.$set(_vm.popup, "show", $$v);
      },
      expression: "popup.show"
    }
  }, [_c('div', {
    staticClass: "p-2 text-left"
  }, [_c('div', {
    staticClass: "font-bold text-2xl"
  }, [_vm._v("为给您提供更好的服务")]), _c('div', [_vm._v(" 根据微信平台相关政策调整，为优化用户使用体验，请授权用户头像和昵称相关信息 ")])])]) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };