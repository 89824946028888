import useMeStore from '@/features/auth/useMeStoreAdapter';
import axios from '@/features/api/axios';

const meStore = useMeStore();

export default async () => {
  const [, , pid] = location.href.match(/[?&](uid|distributor_id)=(\w+)/) || [];
  if (!pid) return;

  if (pid === meStore.shareId) return;

  await axios.post('/v2/student/extension', {
    share_id: pid,
  });
};
