import Vue from 'vue';
import Router from 'vue-router';
import { Toast } from 'vant';
import routes from './routers';
import setTitle from '@/features/common/utils/setTitle';
import {
  plpStart,
  plpEnd,
} from '@/features/common/components/pageLoadProgress.vue';
import jweixin from '@/features/weixin/jweixin';

const originalPush = Router.prototype.push;
Router.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach((to, form, next) => {
  plpStart();
  next();
});
router.afterEach((to) => {
  plpEnd();
  setTitle(to.meta?.title);
});

router.onError(async (err) => {
  Toast.clear();
  Toast('页面加载失败：' + err.message, {
    duration: 3000,
  });
});

export default router;

const jsApiList = `updateAppMessageShareData,updateTimelineShareData,onMenuShareTimeline,onMenuShareAppMessage,hideMenuItems,showMenuItems,chooseWXPay`;
jweixin(jsApiList.split(',')).then(() => {
  wx.hideMenuItems({
    menuList: [
      'menuItem:share:QZone',
      'menuItem:share:qq',
      'menuItem:copyUrl',
      'menuItem:share:email',
      'menuItem:openWithQQBrowser',
      'menuItem:openWithSafari',
    ],
  });
  wx.showMenuItems({
    menuList: [
      'menuItem:share:appMessage',
      'menuItem:share:timeline',
      'menuItem:favorite',
    ], // 要显示的菜单项，所有menu项见附录3
  });
});
